
import { defineComponent, onMounted, Ref, ref } from "vue";
export default defineComponent({
    setup(){
        const btn:Ref<null|HTMLButtonElement> = ref(null);
        const isDown:Ref = ref(false);

        onMounted(()=>{
            if(!btn.value) return
            const button = btn.value;
            button.addEventListener("mousedown",()=>{
                console.log('down')
                isDown.value = true
            })
            button.addEventListener("mouseup",()=>{
                isDown.value = false
            })
        })
        return {
            btn,
            isDown
        }
    }
});
