
import { defineComponent, Ref, ref, watch } from "vue";
export default defineComponent({
  props: {
    text: {
      type: String,
    },
  },
  setup(props) {
    const ch_text = ref();
    const stage: Ref<HTMLDivElement | null> = ref(null);

    watch(props, function () {
      // console.log("props变化");
      stage.value && (stage.value.innerHTML = "")
      const p_arr: string[] = props.text ? props.text.split("\n") : [];
      // console.log(p_arr);

      // ch_text.value
      for (const p of p_arr) {
        if(p === "") continue
        const p_ele: HTMLParagraphElement = document.createElement("p");
        p_ele.style.margin = '7px'
        p_ele.innerText = p;
        stage.value && stage.value.append(p_ele);
      }
    });

    return {
      ch_text,
      stage,
    };
  },
});
