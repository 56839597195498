
import { computed, defineComponent } from "vue";
import Btn from "./Btn.vue";
export default defineComponent({
  components: { Btn },
  props: {
    text: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const ch_text = computed({
      get() {
        return props.text;
      },
      set(val: string | undefined) {
        if (val) {
          emit("chChange", val);
        }
      },
    });

  async function handleCopy(){
    const clipboard = navigator.clipboard;
    if(!(clipboard&&props.text)) return
    await clipboard.writeText(props.text)
  }

  function handleTrim(){
    return ch_text.value = ch_text.value ? ch_text.value.replaceAll("\n",' '):'复制失败';
  }
  function handleTaC() {
    handleTrim()
    setTimeout(handleCopy,0)
  }

    return {
      ch_text,
      handleCopy,
      handleTrim,
      handleTaC
    };
  },
});
